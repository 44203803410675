<template>
  <div id="category-store">
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>

    <div class="cat-store-cont">
      <img :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image" alt="header" class="header-blog-cont"/>
      <img :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image_mv" alt="header" class="header-blog-cont-mb"/>

      
      <AnuncioBar />
      <StoreCatSeccion/>
    </div>

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from "../components/footer";
import StoreCatSeccion from "../components/StoreCatSeccion";
import HeaderHome from "../components/HeaderHome";
import AnuncioBar from "../components/AnuncioBar";
import { mapState, mapActions, mapMutations } from "vuex"; 
let urlpath = require('../global/url');

export default {
  data() {
    return {
      urlpath,
      backgr: [],
    }
  },
  components: {
    HeaderHome,
    StoreCatSeccion,
    Footer,
    AnuncioBar,
  },
  async created() {
      let payload2 = {
        id: 'CTienda',
        option: "cabeceras/section",
      };
      this.backgr = await this.getCabecerasById(payload2);
  },computed: {
        user() {            
        return this.$store.getters['admin/getIdentity'];
        },
    },
  methods: {
    ...mapActions("cabeceras", ["getCabecerasById"]), },
};
</script>
<style scoped>
.header-blog-cont {
  display: flex;
  width: 100%;
  height: 18.646vw;
}
.header-blog-cont-mb {
  display: none;
}

.send-free-advise {
    background: black;
    width: 100%;
}.send-free-advise h1 {
    display: flex;
    align-items: center;
    color: white;
    font-family: 'Rajdhani';
    font-size: 2.865vw;
    margin-bottom: 0;
    justify-content: space-evenly;
    padding: 1vw 3vw;
}span.part1 {
    font-size: 1.302vw;
    line-height: 90.49%;
    text-align: right;
    color: #968220;
    width: 13%;
}span.part2 {
    color: #B70D1D;
}
@media (max-width: 768px) {
  .cat-store-cont {
    /*padding-top: 33.8vw;*/
    padding-top: 24.8vw;
  }
  .header-blog-cont {
    display: none;
  }
  .header-blog-cont-mb {
    display: flex;
    width: 100%;
    height: 30.435vw;
  }
.send-free-advise h1 {
    display: block;
    font-size: 3.865vw;
    padding: 2.24vw;
    text-align: center;
}span.part1 {
    font-size: 3.865vw;
    width: auto;
}
}
</style>