<template>
  <div class="store-cat-section">
    <div class="categories-checklist" id="cat-checklist">
      <div class="cat-sec-check">
        <div class="cat-title">
          <h4 class="titulo-check">CATEGORÍA</h4>
          <h3 class="closeit" @click="filtrarNoCheck()">X</h3>
        </div>
        <label>
          <input
            type="checkbox"
            id="cbox1"
            value="Gorra"
            v-model="CatCheck"
            @change="productdatas"
          />
          Gorras </label
        ><br />
        <label>
          <input
            type="checkbox"
            id="cbox1"
            value="Jersey"
            v-model="CatCheck"
            @change="productdatas"
          />
          Jersey </label
        ><br />
        <label>
          <input
            type="checkbox"
            id="cbox1"
            value="Chamarra"
            v-model="CatCheck"
            @change="productdatas"
          />
          Chamarras </label
        ><br />
        <label>
          <input
            type="checkbox"
            id="cbox1"
            value="Bufanda"
            v-model="CatCheck"
            @change="productdatas"
          />
          Bufanda </label
        ><br />
      </div>
      <div class="cat-sec-check">
        <h4 class="titulo-check">TALLA</h4>
        <div v-for="(talla, key) in tallas" :key="key">
          <label>
            <input
              type="checkbox"
              id="cbox1"
              :value="talla.name"
              v-model="SizeCheck"
              @change="productdatas"
            />
            {{ talla.name }} </label
          ><br />
        </div>
      </div>
    </div>
    <div class="categories-products-cont">
      <div class="header-cat-pro-c">
        <div class="h-c-p-c">
          <h1 class="header-cat-pro-c-t">
            <span v-if="this.CatCheck.length > 0">PRODUCTOS</span>
            <span v-else>{{ nameCate() }}</span>
          </h1>
          <p class="cat-pro-tag">HOMBRE / JERSEY</p>
        </div>

        <div class="Filtrar" @click="filtrarCheck()">
          <img src="../assets/filtros_1.png" />
          <p>Filtrar</p>
        </div>

        <form>
          <select
            name="orderby"
            id="orderby"
            v-model="selectedM"
            ref="seleccionado"
            @change="productdatas"
          >
            <option hidden selected value="0">Seleccionar orden</option>
            <option @change="productdatas" value="1">A - Z</option>
            <option @change="productdatas" value="2">Z - A</option>
            <option @change="productdatas" value="3">
              Precio de mayor a menor
            </option>
            <option @change="productdatas" value="4">
              Precio de menor a mayor
            </option>
          </select>
          <br /><br />
        </form>
        <div v-if="productdatas.length <= 15"></div>
        <div class="Pagination_Div" v-else>
          <paginate
            :initial-page="1"
            :page-count="totalPages()"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'&#9668;'"
            :next-text="'&#9658;'"
            :container-class="'list'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
      <div class="cat-body">
        <div
          class="store-item"
          v-for="slideShop in productdatas.slice(0, elementosPage)"
          :key="slideShop.id"
        >
          <section ><!--v-if="slideShop.status == 'ACTIVO'"-->
            <a :href="'/producto/' + slideShop.url">
              <div class="item-store-cont">
                <img
                  :src="url + '/get-product-image/' + slideShop.image"
                  alt="store"
                />
              </div>
            </a>

            <div class="ti-pr-cont">
              <div
                class="cat-genre"
                v-if="genreName(slideShop.categorias) !== 'N/A'"
              >
                CATEGORIA:
                <a :href="genreWeb(slideShop.categorias)">{{
                  genreName(slideShop.categorias)
                }}</a>
              </div>
              <div
                class="cat-genre"
                v-else-if="genreName(slideShop.categorias) == 'N/A'"
              >
                CATEGORIA:
                <a :href="'/categoria/' + tolc(slideShop.category_name)">{{
                  slideShop.category_name
                }}</a>
              </div>
              <div
                class="cat-genre"
                v-else-if="
                  slideShop.categorias == 'No se encontraron coincidencias'
                "
              >
                CATEGORIA:
                <a :href="'/categoria/' + tolc(slideShop.category_name)">{{
                  slideShop.category_name
                }}</a>
              </div>
              <div class="ti-cont">
                <a :href="'/producto/' + slideShop.url">
                  <h5>{{ slideShop.name }}</h5></a
                >
                <!--  <h6>{{slideShop.size_name}}</h6> -->
              </div>
              <div class="pr-cont">
                <span> $ {{ slideShop.price }}</span> MXN
              </div>
              <!--<div>
                                              <a class="comprar-btn" :href="'/producto/'+replace(slideShop.name)">  COMPRAR </a>
                                          </div>-->
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://unpkg.com/vuejs-paginate@0.9.0"></script>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";

import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      page: 1,
      elementosPage: 15,
      datosPaginados: [],
      CatCheck: [],
      SizeCheck: [],
      OrderCheck: [],
      NewData: [],
      lendProd: 0,
      updateLendProd: 0,
      nameCat: "",
      selectedM: 0,
      selectedThis: false,
      genreCatWeb: "",
    };
  },
  components: {
    HeaderHome,
    Footer,
    VueperSlides,
    VueperSlide,
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  created() {
    this.findAll();
    this.findByCategoryName();
    this.getSizes("Tallas");
  },
  computed: {
    tallas() {
      return this.$store.getters["size/data"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    url() {
      return this.$store.getters["main/baseURL"];
    },
    list() {
      return this.$store.getters["product/data"];
    },
    productdata() {
      let algo = this.$store.getters["product/data"];
      return algo;
    },
    productdatas(clickCallback) {
      let arr;
      let algo = [];
      if (this.$route.params.name == "chamarras") {
        arr = this.$store.getters["product/data2"].reverse();
      } else if (this.$route.params.name == "gorras") {
        arr = this.$store.getters["product/data2"].reverse();
      } else if (this.$route.params.name == "niño") {
        arr = this.$store.getters["product/data2"].reverse();
      } else if (this.$route.params.name == "edicion-limitada") {
        arr = this.$store.getters["product/data2"].reverse();
      } else if (this.$route.params.name == "promociones") {
        arr = this.$store.getters["product/data2"].reverse();
      } else if (this.$route.params.name == "bufanda") {
        arr = this.$store.getters["product/data2"].reverse();
      } else if (this.$route.params.name == "jersey") {
        arr = this.$store.getters["product/data2"].reverse();
      } else if (this.$route.params.name == "mujer") {
        arr = this.$store.getters["product/data2"].reverse();
      } else if (this.$route.params.name == "hombre") {
        arr = this.$store.getters["product/data2"].reverse();
      } else if (this.$route.params.name == "lo-mas-vendido") {
        arr = this.$store.getters["product/data2"].reverse();
      } else if (this.$route.params.name == null) {
        arr = this.$store.getters["product/data"].reverse();
      } else if (selectedThis == true) {
        arr = this.$store.getters["product/data"].reverse();
      }
      
      for (let i = 0; i < arr.length; i++) {
        if(arr[i].status=='ACTIVO'){
          algo.push(arr[i])
        }
      }

      algo;

      /*
       // algo = this.$store.getters['product/data'];
     let val = this.$data.selected.value;
      if (val == 1) {
        algo = this.$store.getters["product/data"];
        algo.sort(function (a, b) {
          let textA = a.name.toUpperCase();
          let textB = b.name.toUpperCase();
           textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      }*/

      let arreglofilt = [];
      let arreglofiltz = [];

      let check = this.CatCheck;
      let check2 = this.SizeCheck;
      let check3 = this.selectedM;
      //let check3 = this.OrderCheck;

      if (check3.length > 0) {
        if (check3 == 1) {
          algo = algo.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        } else if (check3 == 2) {
          algo = algo
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .reverse();
        } else if (check3 == 3) {
          algo = algo
            .sort(function (a, b) {
              if (a.price < b.price) {
                return -1;
              }
              if (a.price > b.price) {
                return 1;
              }
              return 0;
            })
            .reverse();
        } else if (check3 == 4) {
          algo = algo.sort(function (a, b) {
            if (a.price < b.price) {
              return -1;
            }
            if (a.price > b.price) {
              return 1;
            }
            return 0;
          });
        }
      }

      if (check.length > 0) {
        algo = this.$store.getters["product/data"];

        for (var i = 0; i < algo.length; i++) {
          let splitname = algo[i].name.split(" ");
          let firts = splitname[0];

          for (var f = 0; f < check.length; f++) {
            if (firts == check[f]) {
              arreglofilt.push(algo[i]);
            }
          }
        }
      } else {
        for (var i = 0; i < algo.length; i++) {
          arreglofilt.push(algo[i]);
        }
      }

      let sizestock = 1;

      let filtro3 = [];
      let contador = arreglofilt.length - 1;

      if (check2.length > 0) {
        for (var j = 0; j < arreglofilt.length; j++) {
          let contStock = arreglofilt[j].stock;

          if (contStock != "No se encontraron coincidencias") {
            for (var i = 0; i < contStock.length; i++) {
              for (var f = 0; f < check2.length; f++) {
                if (contStock[i].size_name == check2[f]) {
                  arreglofiltz.push(arreglofilt[j]);
                }
              }
            }
          }
        }
      } else {
        for (var i = 0; i < arreglofilt.length; i++) {
          arreglofiltz.push(arreglofilt[i]);
        }
      }

      if (arreglofiltz.length == 0) {
        return arreglofiltz;
      } else {
        this.lendProd = arreglofiltz.length;
        let num = clickCallback.page;
        for (let i = 0; i < arreglofiltz.length; i++) {
          if (arreglofiltz[i].id >= num) {
            let y = i % arreglofiltz.length;
            let first_y_elements = arreglofiltz.slice(0, y);
            let remaining_elements = arreglofiltz.slice(y, arreglofiltz.length);
            return (arreglofiltz = [
              ...remaining_elements,
              ...first_y_elements,
            ]);
          }
        }
      }
    },
    /*dadapage(){
      let algo = this.productdatas;
      let find = false;
      for (let i = 0; i < algo.length; i++) {
        if(algo[i].categorias !== 'No se encontraron coincidencias'){
          for (let j = 0; j < arraynx.length; j++) {
            if(arraynx[i].category_name == 'MUJER'){
              find = true;
              this.genreCat = 'MUJER'
            }else if(arraynx[i].category_name == 'NIÑO'){
              find = true;
              this.genreCat = 'NIÑO'
            }else if(arraynx[i].category_name == 'HOMBRE'){
              find = true;
              this.genreCat = 'HOMBRE'
            }
          }          
        }
      }
    },*/
  },
  methods: {
    ...mapActions("product", ["findAll"]),
    ...mapActions("product", ["findById"]),
    ...mapActions("product", ["findByCategoryName"]),
    ...mapActions("product", ["getImageFile"]),
    ...mapActions("size", ["getSizes"]),
    findAll: async function () {
      let result = await this.$store.dispatch("product/findAll", {
        option: "tienda",
      });
      this.info = result;
      return result;
    },
    findByCategoryName: async function () {
      let name = this.$route.params.name;
      let result = await this.$store.dispatch("product/findByCategoryName", {
        option: "product/by/categoryname",
        name: name,
      });
      this.info = result;
      return result;
    },

    genreName(genre) {
      let name = "N/A";
      for (let i = 0; i < genre.length; i++) {
        if (genre[i].category_name == "MUJER") {
          name = "MUJER";
        } else if (genre[i].category_name == "HOMBRE") {
          name = "HOMBRE";
        } else if (genre[i].category_name == "NIÑO") {
          name = "NIÑO";
        }
      }
      return name;
    },

    genreWeb(genre) {
      let name = "#";
      for (let i = 0; i < genre.length; i++) {
        if (genre[i].category_name == "MUJER") {
          name = "/categoria/mujer";
        } else if (genre[i].category_name == "HOMBRE") {
          name = "/categoria/hombre";
        } else if (genre[i].category_name == "NIÑO") {
          name = "/categoria/niño";
        }
      }
      return name;
    },

    orderingData(num, data) {
      if (num == 1) {
        return data.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      } else if (num == 2) {
        return data
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .reverse();
      }
    },

    tolc(palabra) {
      let word = palabra.replaceAll(" ", "-");
      let newword = word
        .normalize("NFD")
        .replace(
          /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
          "$1"
        )
        .normalize();
      return newword.toLowerCase();
    },

    nameCate() {
      let check = this.CatCheck;
      let check2 = this.SizeCheck;
      if (this.$route.params.name == "chamarras") {
        return "CHAMARRAS";
      } else if (this.$route.params.name == "gorras") {
        return "GORRAS";
      } else if (this.$route.params.name == "niño") {
        return "NIÑO";
      } else if (this.$route.params.name == "edicion-limitada") {
        return "EDICIÓN LIMITADA";
      } else if (this.$route.params.name == "promociones") {
        return "PROMOCIONES";
      } else if (this.$route.params.name == "bufanda") {
        return "BUFANDAS";
      } else if (this.$route.params.name == "jersey") {
        return "JERSEY";
      } else if (this.$route.params.name == "damas") {
        return "DAMAS";
      } else if (this.$route.params.name == "caballeros") {
        return "CABALLEROS";
      } else if (this.$route.params.name == "lo-mas-vendido") {
        return "LO MÁS VENDIDO";
      } else if (this.$route.params.name == null) {
        return "PRODUCTOS";
      } else if (check > 0) {
        return "PRODUCTOS";
      }
    },

    productDetail: function (name) {
      name = name.replaceAll(" ", "-");
      this.$router.push("/producto/" + name).catch((err) => {});
    },
    replace(name) {
      return (name = name.replaceAll(" ", "-"));
    },
    totalPages() {
      return Math.ceil(this.list.length / this.elementosPage);
    },
    getDataPages(noPagina) {
      this.datosPaginados = [];
      let ini = noPagina * this.elementosPage - this.elementosPage;
      let fin = noPagina * this.elementosPage;

      this.datosPaginados = this.list.slice(ini, fin);
    },

    clickCallback: function (pageNum) {
      if (pageNum == 1) {
        this.page = pageNum;
      } else if (pageNum == 2) {
        this.page = pageNum + 14;
      } else {
        this.page = pageNum + 29;
      }
      return this.page;
    },

    FiltrarGood(name, check) {
      let splitname = name.split(" ");
      let firts = splitname[0];
      let isvalid = false;
      for (var i = 0; i < check.length; i++) {
        if (firts == check[i]) {
          isvalid = true;
        }
      }
      return isvalid;
    },

    FiltrarSize(size, verifi) {
      let isvalid = false;
      for (var i = 0; i < size.length; i++) {
        for (var f = 0; f < verifi.length; f++) {
          if (size[i].size_name == verifi[f]) {
            isvalid = true;
          }
        }
      }

      return isvalid;
    },
    FiltrarCat() {
      let arrp = [];
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].name.substr(0, 5) == this.CatCheck[0].substr(0, 5)) {
          arrp.push(this.list[i]);
        }
      }
      this.NewData = arrp;

      return this.NewData;
    },
    replace(name) {
      return (name = name.replaceAll(" ", "-").replace("/", "_"));
    },
    filtrarCheck() {
      document.getElementById("cat-checklist").classList.add("showthis");
      /*if(document.getElementById('cat-checklist').classList.contains("showthis")) {
        document.getElementById('cat-checklist').classList.remove('showthis');
      }*/
    },
    filtrarNoCheck() {
      document.getElementById("cat-checklist").classList.remove("showthis");
    },
  },

  /**titulo pagina */
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Tienda - Cimarrones de Sonora";
      },
    },
  },
};
</script>

<style>
.comprar-btn {
  margin-top: 1vw;
  width: 23.01vw;
}
.categories-checklist {
  padding: 11.146vw 4vw;
  width: 20.469vw;
  border-right: 1px solid gainsboro;
}
.cat-sec-check {
  margin-bottom: 3vw;
}
.titulo-check {
  font-size: 1.042vw;
  line-height: 90.49%;
  color: #032a4b;
  font-family: "Rajdhani";
}
.cat-sec-check label {
  font-size: 0.781vw;
  line-height: 253.49%;
  color: #8293a2;
  font-family: "Rajdhani";
}
input#cbox1 {
  margin-right: 0.885vw;
}
.store-cat-section {
  display: flex;
}
.categories-products-cont {
  width: 100%;
}
.header-cat-pro-c {
  display: flex;
  align-items: center;
  padding: 3.49vw;
}
h1.header-cat-pro-c-t {
  font-size: 1.823vw;
  line-height: 90.49%;
  text-align: left;
  color: #032a4b;
  font-family: "Rajdhani";
}
.cat-pro-tag {
  margin-top: 0.781vw;
  font-size: 0.781vw;
  line-height: 90.49%;
  color: #8293a2;
}
select#orderby {
  width: 11.667vw;
  height: 3.177vw;
  font-size: 0.781vw;
  border-radius: 0 !important;
  color: #8293a2;
  border: 1px solid gainsboro;
  margin-left: 8vw;
  padding-left: 0.5vw;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../assets/flecha-blue.png");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 10vw;
  background-size: 0.6vw;
}
.header-cat-pro-c-t span {
  font-family: "Rajdhani";
}

/**producto */
.pr-cont {
  font-size: 0.9375vw;
  font-family: "Rajdhani";
}
.pr-cont span {
  font-size: 0.9375vw;
  font-family: "Rajdhani" !important;
}
.ti-pr-cont h5 {
  font-size: 1.25vw;
  font-family: "Rajdhani";
  text-align: center;
  color: #032a4b;
}
.ti-pr-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2vw 0 4vw;
}
.cat-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1vw 2vw 1vw 2vw;
}
.item-store-cont {
  border: 1px solid #8293a1;
  border-radius: 1vw;
  padding: 1.5vw;
  height: 22vw;
}
.categories-products-cont .ti-pr-cont h5 {
  text-transform: uppercase;
}
.categories-products-cont .pr-cont {
  color: white;
  font-family: "Rajdhani";
}
.Filtrar {
  display: none;
}

.Pagination_Div ul {
  margin-left: 21vw;
  display: flex;
}
.Pagination_Div li {
  margin-right: 2vw;
  color: #032a4b !important;
  font-size: 0.8333333333333334vw;
}
h3.closeit {
  display: none;
}
.ti-cont {
  width: 18vw;
  margin-top: 0.99vw;
}
@media (max-width: 768px) {
  .ti-cont {
    width: 32vw;
  }
  .showthis {
    display: block !important;
    position: absolute;
    background: white;
    width: 39.4vw;
    margin-top: 28.4vw;
    margin-left: 5.3vw;
    z-index: 99;
    border: 1px solid gainsboro;
  }
  h3.closeit {
    display: block;
    font-size: 6vw;
    font-family: "Rajdhani";
    color: #ab3231;
    cursor: pointer;
  }
  .cat-store-cont {
    padding-top: 23.8vw;
  }
  .cat-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .titulo-check {
    font-size: 4.042vw;
  }
  .cat-sec-check label {
    font-size: 2.781vw;
  }
  .categories-checklist {
    display: none;
  }

  .header-cat-pro-c {
    display: flex;
    align-items: center;
    padding: 5.49vw;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .h-c-p-c {
    margin-right: 5vw;
    width: 82vw;
  }

  h1.header-cat-pro-c-t {
    font-size: 5.555555555555555vw;
    line-height: 90.49%;
    text-align: left;
    color: #032a4b;
    font-family: "Rajdhani";
  }
  .cat-pro-tag {
    margin-top: 0.781vw;
    font-size: 2.4154589371980677vw;
    line-height: 90.49%;
    color: #8293a2;
  }
  .Filtrar {
    display: flex;
    width: 39.371980676328505vw;
    height: 11.352657004830919vw;
    font-size: 2.898550724637681vw;
    border-radius: 0 !important;
    color: #8293a2;
    border: 1px solid gainsboro;
    margin-top: -5.5vw;
    margin-right: 3.140096618357488vw;
    /*margin-left: 5vw;*/
    place-content: center;
    /*padding: inherit; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .Filtrar p {
    margin: 0;
  }
  .Filtrar img {
    width: auto;
    height: 4.830917874396135vw;
    margin-top: -0.2vw;
    margin-right: 2.898550724637681vw;
  }
  select#orderby {
    width: 39.371980676328505vw;
    height: 11.352657004830919vw;
    font-size: 2.898550724637681vw;
    border-radius: 0 !important;
    color: #8293a2;
    border: 1px solid gainsboro;
    text-align: left;
    margin-left: 0vw;
    background-position-x: 33vw;
    background-size: 2.6vw;
  }
  .Pagination_Div ul {
    margin-left: 51vw;
    display: flex;
  }
  .Pagination_Div li {
    margin-right: 4vw;
    color: #032a4b !important;
    font-size: 3.864734299516908vw;
  }

  .cat-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1vw 2vw 1vw 2vw;
    justify-content: space-evenly;
  }

  #storeSection .store-item,
  .categories-products-cont .store-item {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 1.5s;
  }
  .item-store-cont {
    border: 1px solid #8293a1;
    border-radius: 3.6231884057971016vw;
    padding: 3vw;
    width: 41vw;
    height: auto;
  }
  .item-store-cont img {
    height: 37.43961352657005vw !important;
    width: auto;
  }

  #storeSection .ti-pr-cont h5,
  .categories-products-cont .ti-pr-cont h5 {
    font-size: 2.657004830917874vw;
    font-family: "Rajdhani";
  }
  #storeSection .pr-cont,
  .categories-products-cont .pr-cont {
    padding: 3vw;
    background: #968220;
    width: fit-content !important;
    height: 3.24vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Rajdhani";
    transition: 0.25s;
    font-size: 2.1739130434782608vw;
  }
  .pr-cont {
    font-size: 2.1739130434782608vw;
    font-family: "Rajdhani" !important;
    margin-right: 0.2vw;
  }
  .pr-cont span {
    font-size: 2.1739130434782608vw;
    font-family: "Rajdhani" !important;
  }
}
</style>